// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
// require("jquery")

// import "bootstrap"
// import "../stylesheets/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// Import jQuery first
import $ from 'jquery'
global.$ = global.jQuery = $

// Rails UJS
import Rails from "@rails/ujs"
Rails.start()

// Turbolinks
import Turbolinks from "turbolinks"
Turbolinks.start()

// ActiveStorage
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// Channels
import "channels"

import "jquery"

// Bootstrap
import "bootstrap"

// Import our styles
import "../stylesheets/application"